import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import "lib-flexible"

ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.MessageBox.setDefaults({
    closeOnClickModal: false
})

Vue.prototype.$axios = axios
Vue.use(ElementUI)
Vue.config.productionTip = false

const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
    let previous = 0
    let newFunc = func
    if (event === 'click') {
        newFunc = function () {
            const now = new Date().getTime()
            if (previous + 1000 <= now) {
                func.apply(this, arguments)
                previous = now
            }
        }
    }
    on.call(this, event, newFunc)
}

Vue.directive('preventClick', {
    inserted(el) {
        console.log(el.disabled)
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, 1500);
            }
        });
    }
});

router.beforeEach((to, from, next) => {
    let code = sessionStorage.getItem("code")
    //判断此用户是否有权限来访问路由
    //控制用户在路由的操作
    if (to.path == '/404') {
        next()
    } else if (to.path == '/401') {
        next()
    } else {
        if (code == '0' && to.path == '/login') {
            next('')
        } else if (code == '0' && to.path != '/login') {
            next()
        } else if (code != '0' && to.path == '/login') {
            next()
        } else if (code != '0' && to.path != '/login') {
            next('./login')
        }
    }
});
new Vue({
    render: h => h(App),
    router
}).$mount('#app')