<template>
  <div class="header">
    <div class="title">机构后台管理系统</div>
    <!-- <span v-if="$route.path === '/OrderManagement'">剩余余额{{ surplusMoney * 10 }}</span> -->
    <div class="user">
      <span style="margin-right: 20px">{{ name }}</span>
      <template>
        <el-popconfirm @onConfirm="logout" title="你确定要退出吗？">
          <span class="logout" slot="reference">退出</span>
        </el-popconfirm>
      </template>
    </div>
  </div>
</template>

<script>
import { log_out } from "../api/login";
import { getBalanceInfo } from "../api/header";
export default {
  data() {
    return {
      name: "",
      // username: "18174058300",
      surplusMoney: "",
      totalMoney: "",
      pushNum: ""
    };
  },
  mounted() {
    this.name = sessionStorage.getItem("phone");
    getBalanceInfo({ token: sessionStorage.getItem("token") }).then((res) => {
     
      if (res.code == 200) {
        console.log(res);
        this.surplusMoney = res.data.surplusMoney;
        this.totalMoney = res.data.totalMoney;
        this.pushNum = res.data.pushNum;
      }
    });
  },
  methods: {
    logout() {
      log_out({ token: sessionStorage.getItem("token") }).then((res) => {
        if (res.code == 200) {
          sessionStorage.clear();
          location.reload();
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 65px;
  background: #2468a9 10000%;
  color: #fff;
  .user {
    font-size: 14px;
  }
  .title {
    font-size: 18px;
    font-weight: 900;
  }
  .logout {
    cursor: pointer;
  }
}
</style>
