import request from './request.js';
//获取验证码
export function user_login({
    code,
    phone
}) {
    return request({
        url: '/system-user/login/post-login',
        method: 'post',
        data: {
            code,
            phone,
            baseInfoType: 1
        }
    })
}
export function post_login({
    code,
    loginPwd,
    loginUser,
    phone
}) {
    return request({
        url: '/system-user/login/getPhoneCode',
        method: 'post',
        data: {
            code,
            loginPwd,
            loginUser,
            phone,
            baseInfoType: 1
        }
    })
}
//登出操作
export function log_out({
    token
}) {
    return request({
        url: '/system-user/login/log-out',
        method: 'GET',
        params: { token }
    })
}
//查看当前用户信息
export function find_user({
    token
}) {
    return request({
        url: '/system-user/login/find-user',
        method: 'GET',
        params: { token }
    })
}