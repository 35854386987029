import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../views/layout.vue'

Vue.use(Router)

const Routes = [{
        path: '*',
        redirect: sessionStorage.getItem("Permission")
    },
    {
        path: '/login',
        name: 'login',
        component: () =>
            import('../components/login.vue')
    },
    {
        path: '/',
        redirect: sessionStorage.getItem("Permission"),
        component: Layout, // 导入组件 Layout
        hidden: true,
        children: [{
                path: '/OrderManagement',
                component: () =>
                    import('@/views/OrderManagement')
            },
            {
                path: '/AccountManagement',
                component: () =>
                    import('@/views/AccountManagement')
            },
            {
                path: '/user',
                component: () =>
                    import('@/views/user/index.vue')
            },
            {
                path: '/AutoOrderManagement',
                component: () =>
                    import('@/views/AutoOrderManagement/index.vue')
            },
            {
                path: '/SubplanData',
                component: () =>
                    import('@/views/SubplanData/index.vue')
            }
        ]
    }
]

const createRouter = () => new Router({
    routes: Routes
})

const router = createRouter()

router.beforeEach((to, from, next) => {
    const role = sessionStorage.getItem('perms');
    if (role) {
        if (role === 'system' && to.path === '/user') {
            next('/OrderManagement');
        } else if (role === 'user' && to.path !== '/user') {
            next('/user');
        } else {
            next();
        }
    } else {
        next();
    }
})

export default router;